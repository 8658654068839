import {
  CatalogEvolveResourceExternalEntityDtoParsed,
  CatalogWithExternalEntitiesDto,
  CourseCopyPreviewDto,
  ExternalEntityDto,
  OsmosisTokenDto,
  UserDto
} from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import { AppLinkData, } from '../../apis/eols-app-link/eols-app-link.dtos';
import { EvolveProductDto } from '../../apis/sherpath-app-facade-service/sherpath-app-facade-service.dtos';
import {
  CoursewareUserHistoryStateKey,
  EolsUserHistoryResponseDto
} from '../../apis/eols-user-crud/eols-user-crud.dtos';
import { CourseSectionDto } from '../../apis/eols-course-crud/eols-course-crud.dtos';
import { RecTaxonomyNodeDto } from '../../apis/rec-gateway/rec-gateway.dtos';
import { PrimaryTaxonomy } from '../../apis/rec-gateway/rec-gateway.models';
import { SyllabusItemDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.dtos';
import {
  AssessmentDto,
  AssessmentSubmissionDto,
  AssignmentDto
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { CrosswalkUserDto } from '../../apis/eols-user-management-service/eols-user-management-service.dtos';
import { EbookFilterState } from '../../components/ebook-filter/ebook-filter.models';
import { ActiveSyllabusItemTypeDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.constants';
import {
  FeatureFlagDto,
  FeatureFlagsGroupedDto
} from '../../apis/eols-features-api/eols-features-api.dtos';
import {
  contentCreateMethod,
  courseCreateMethod,
  sectionTitleMethod
} from '../../pages/course-builder/courseBuilder.constants';
import { EolsUserEngagementDto } from '../../apis/eols-user-engagement/eols-user-engagement.dtos';
import { AppLinkOutConfig } from '../../apis/eols-app-link/eols-app-link.models';
import { Messages } from '../../translations/message.models';
import {
  GroupActivityDto,
  SequenceMap
} from '../../apis/ocs-api-service/ocs-api-service.dtos';
import { MigratedCourseEntitlementDto } from '../../apis/sherpath-course-management-service/sherpath-course-management-service.utilities';
import { SyllabusFolderLocationInfo } from '../../pages/catalog/catalog.models';
import { ClinicalSkillsFilterState } from '../../components/clinical-skills-filter/clinical-skills-filter.models';
import { COURSE_SETTINGS_FEATURE_FLAG } from '../../apis/eols-features-api/eols-features-api.constants';

export enum CourseBuilderField {
  CONTENT_CREATE_METHOD = 'CONTENT_CREATE_METHOD', // eslint-disable-line no-shadow
  COURSE_CREATE_METHOD = 'COURSE_CREATE_METHOD', // eslint-disable-line no-shadow
  COURSE_NAME = 'COURSE_NAME',
  CUSTOM_SECTION_TITLE = 'CUSTOM_SECTION_TITLE',
  END_DATE = 'END_DATE',
  FIRST_FOLDER_TITLE = 'FIRST_FOLDER_TITLE',
  IS_COURSE_LOCKED = 'IS_COURSE_LOCKED',
  IS_INCLUDE_DURATION_IN_TITLE = 'IS_INCLUDE_DURATION_IN_TITLE',
  NUMBER_OF_SECTIONS = 'NUMBER_OF_SECTIONS',
  SECTION_TITLE_METHOD = 'SECTION_TITLE_METHOD', // eslint-disable-line no-shadow
  SHOW_CONTENT = 'SHOW_CONTENT',
  START_DATE = 'START_DATE',
  TAXONOMY_IDS = 'TAXONOMY_IDS',
  SELECT_EXISTING_COURSE = 'SELECT_EXISTING_COURSE',
  COPY_COURSE_START_DATE = 'COPY_COURSE_START_DATE'
}

export interface CourseBuilderStore {
  isNewCourse: boolean;
  [CourseBuilderField.CONTENT_CREATE_METHOD]: contentCreateMethod;
  [CourseBuilderField.COURSE_CREATE_METHOD]: courseCreateMethod;
  [CourseBuilderField.COURSE_NAME]: string;
  [CourseBuilderField.CUSTOM_SECTION_TITLE]: string;
  [CourseBuilderField.END_DATE]: string;
  [CourseBuilderField.FIRST_FOLDER_TITLE]: string;
  [CourseBuilderField.IS_COURSE_LOCKED]: boolean;
  [CourseBuilderField.IS_INCLUDE_DURATION_IN_TITLE]: boolean;
  [CourseBuilderField.NUMBER_OF_SECTIONS]: number;
  [CourseBuilderField.SECTION_TITLE_METHOD]: sectionTitleMethod;
  [CourseBuilderField.SHOW_CONTENT]: boolean;
  [CourseBuilderField.START_DATE]: string;
  [CourseBuilderField.TAXONOMY_IDS]: string[];
  [CourseBuilderField.SELECT_EXISTING_COURSE]: string;
  [CourseBuilderField.COPY_COURSE_START_DATE]: string;
  selectedTypes: ActiveSyllabusItemTypeDto[];
  courseCopyPreviewDto: CourseCopyPreviewDto;
  isDeepLinkCourseCopy: boolean;
}

export enum ReduxPage {
  CATALOG_PAGE = 'catalogPage',
  SHERPATH_LESSON_EDITOR_PAGE = 'sherpathLessonEditorPage',
  EVOLVE_RESOURCES_EDITOR_PAGE = 'evolveResourcesEditorPage',
  SIMULATION_EDITOR_PAGE = 'simulationEditorPage',
  SKILL_EDITOR_PAGE = 'skillEditorPage',
  EBOOK_EDITOR_PAGE = 'ebookEditorPage',
  SHADOW_HEATH_EDITOR_PAGE = 'shadowHealthEditorPage',
  SHERPATH_POWERPOINT_EDITOR_PAGE = 'sherpathPowerpointEditorPage',
  SHERPATH_GROUP_ACTIVITY_EDITOR_PAGE = 'sherpathGroupActivityEditorPage',
  SHERPATH_CASE_STUDY_EDITOR_PAGE = 'sherpathCaseStudyEditorPage',
  OSMOSIS_VIDEO_EDITOR_PAGE = 'osmosisVideoEditorPage',
  OSMOSIS_VIDEO_PLAYER_PAGE = 'osmosisVideoPlayerPage',
  COURSE_PLAN_PAGE = 'coursePlanPage'
}

export enum FilterStateField {
  EBOOK_FILTER_STATE = 'ebookFilterState',
  CLINICAL_SKILLS_FILTER_STATE = 'clinicalSkillsFilterState',
  SELECTED_PRODUCT = 'selectedProduct',
  RESOURCE_STATUS_FILTER = 'resourceStatusFilter',
  RESOURCE_TYPE_FILTER = 'resourceTypeFilter',
  RESOURCE_GRADING_FILTER = 'resourceGradingFilter',
  IS_HESI_FOCUS_CHAPTER_FILTER_ENABLED = 'isHesiFocusChapterFilterEnabled'
}

export interface FilterState {
  ebookFilterState: EbookFilterState;
  clinicalSkillsFilterState: ClinicalSkillsFilterState;
  selectedProduct: string;
  resourceStatusFilter: Array<string>;
  resourceTypeFilter: Array<ActiveSyllabusItemTypeDto>;
  resourceGradingFilter: Array<string>;
  isHesiFocusChapterFilterEnabled: boolean;
}

export interface CoursewareStore {
  userEngagementReport: EolsUserEngagementDto[];
  userHistory: EolsUserHistoryResponseDto[];
  userHistoryStateCompletedRequests: CoursewareUserHistoryStateKey[];
  courseSectionId: string;
  currentCourse: CourseSectionDto;
  errors: Error[];
  evolveProducts: EvolveProductDto[];
  evolveUser: CrosswalkUserDto;
  featureFlagsGrouped: FeatureFlagsGroupedDto[];
  courseSettingsFlags: Partial<Record<COURSE_SETTINGS_FEATURE_FLAG, FeatureFlagDto>>;
  isbns: string;
  vantageIsbns: string[];
  isCourseOwner: boolean;
  linkData: AppLinkData;
  messages: Messages;
  pendingRequestCount: number;
  roleId: string;
  userCourseOwnerRecords: EolsUserHistoryResponseDto[];
  isUserCourseOwnerRecordsFetched: boolean;
  userId: string;
  users: UserDto[];
  eolsUser: UserDto;
  catalog: CatalogWithExternalEntitiesDto;
  primaryTaxonomies: PrimaryTaxonomy[];
  taxonomies: RecTaxonomyNodeDto[];
  assignments: AssignmentDto[];
  assessments: AssessmentDto[];
  assessmentSubmissionsMap: Record<string, AssessmentSubmissionDto[]>;
  evolveInstructorResourceDataMap: Record<string, CatalogEvolveResourceExternalEntityDtoParsed['_parsedData']>;
  checkedSyllabusItemIds: string[];
  syllabusFolderInfo: SyllabusFolderLocationInfo;
  courseBuilderState: CourseBuilderStore;
  externalEntities: ExternalEntityDto[];
  isBatchEditModeEnabled: boolean;
  isDragDropModeEnabled: boolean;
  syllabusItems: SyllabusItemDto[];
  isAppLinkAssignmentCreateFlow: boolean;
  appLinkAssignmentStudents: number[];
  simulationAssignment: AssignmentDto;
  crosswalkUsers: UserDto[];
  assessmentStartTimeMap: Record<string, Date>;
  registeredToken: string;
  appLinkCookies: {
    token: string;
    linkId: string;
  };
  collapsedFolderIds: string[];
  skillSubmissionRecords: EolsUserHistoryResponseDto[];
  skillStaticData: {
    data: string;
    id: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    moduleInfoList: any;
    vtwId: string;
  };
  [ReduxPage.CATALOG_PAGE]: {
    catalog: CatalogWithExternalEntitiesDto;
    primaryTaxonomies: PrimaryTaxonomy[];
    filterState: FilterState;
  };
  [ReduxPage.SHERPATH_LESSON_EDITOR_PAGE]: {
    catalog: CatalogWithExternalEntitiesDto;
  };
  [ReduxPage.SKILL_EDITOR_PAGE]: {
    catalog: CatalogWithExternalEntitiesDto;
  };
  [ReduxPage.EVOLVE_RESOURCES_EDITOR_PAGE]: {
    catalog: CatalogWithExternalEntitiesDto;
  };
  [ReduxPage.SHADOW_HEATH_EDITOR_PAGE]: {
    catalog: CatalogWithExternalEntitiesDto;
  };
  [ReduxPage.SIMULATION_EDITOR_PAGE]: {
    catalog: CatalogWithExternalEntitiesDto;
  };
  [ReduxPage.EBOOK_EDITOR_PAGE]: {
    primaryTaxonomies: PrimaryTaxonomy[];
  };
  [ReduxPage.SHERPATH_POWERPOINT_EDITOR_PAGE]: {
    catalog: CatalogWithExternalEntitiesDto;
  };
  [ReduxPage.COURSE_PLAN_PAGE]: {
    filterState: FilterState;
  };
  [ReduxPage.SHERPATH_GROUP_ACTIVITY_EDITOR_PAGE]: {
    catalog: CatalogWithExternalEntitiesDto;
  };
  [ReduxPage.SHERPATH_CASE_STUDY_EDITOR_PAGE]: {
    catalog: CatalogWithExternalEntitiesDto;
  };
  [ReduxPage.OSMOSIS_VIDEO_EDITOR_PAGE]: {
    catalog: CatalogWithExternalEntitiesDto;
  };
  [ReduxPage.OSMOSIS_VIDEO_PLAYER_PAGE]: {
    catalog: CatalogWithExternalEntitiesDto;
  };
  batchEditSelectedSyllabusItems: SyllabusItemDto[];
  batchEditUpdatedSyllabusItems: SyllabusItemDto[];
  moduleSequenceMap: SequenceMap;
  enableDeepLink: boolean;
  groupActivity: GroupActivityDto;
  hasRunAuthessHealthCheck: boolean;
  osmosisTokenDto: OsmosisTokenDto;
  migratedEntitlements: MigratedCourseEntitlementDto[];
  abTestFlavors: FeatureFlagDto[];
  progressIndicatorValues: {
    completed: number;
    total: number;
  };
  isShowShadowHealthOrientationBanner: boolean;
  isChatBotCanaryFlagChecked: boolean;
  collapsedFilterTitles: string[];
}

export type ReduxPageWithCatalog = Extract<ReduxPage,
  ReduxPage.CATALOG_PAGE |
  ReduxPage.SKILL_EDITOR_PAGE |
  ReduxPage.SIMULATION_EDITOR_PAGE |
  ReduxPage.SHERPATH_LESSON_EDITOR_PAGE |
  ReduxPage.EVOLVE_RESOURCES_EDITOR_PAGE |
  ReduxPage.SHADOW_HEATH_EDITOR_PAGE |
  ReduxPage.SHERPATH_POWERPOINT_EDITOR_PAGE |
  ReduxPage.SHERPATH_GROUP_ACTIVITY_EDITOR_PAGE |
  ReduxPage.SHERPATH_CASE_STUDY_EDITOR_PAGE |
  ReduxPage.OSMOSIS_VIDEO_EDITOR_PAGE |
  ReduxPage.OSMOSIS_VIDEO_PLAYER_PAGE>

export type ReduxPageWithPrimaryTaxonomies = Extract<ReduxPage,
  ReduxPage.CATALOG_PAGE |
  ReduxPage.EBOOK_EDITOR_PAGE>;

export type ReduxPageWithFilter = Extract<ReduxPage,
  ReduxPage.CATALOG_PAGE |
  ReduxPage.COURSE_PLAN_PAGE>;

export type NavigateToApp = (config: AppLinkOutConfig, isNewTab?: boolean, token?: string) => void;

export type FilterStateType = Extract<FilterStateField,
  FilterStateField.EBOOK_FILTER_STATE |
  FilterStateField.CLINICAL_SKILLS_FILTER_STATE |
  FilterStateField.RESOURCE_GRADING_FILTER |
  FilterStateField.RESOURCE_STATUS_FILTER |
  FilterStateField.RESOURCE_TYPE_FILTER |
  FilterStateField.SELECTED_PRODUCT |
  FilterStateField.IS_HESI_FOCUS_CHAPTER_FILTER_ENABLED>;
export type AdobeAnalyticsPageData = {
  visitor: {
    accessType: string;
    loginStatus: string;
    loginSuccess: string;
    loginFailure: string;
    accountId: string;
    accountName: string;
    ipAddress: string;
    userId: string;
    departmentId: string;
    departmentName: string;
    usagePathInfo: string;
  };
  page: {
    businessUnit: string;
    environment: string;
    productName: string;
    language: string;
    name: string;
    type: string;
    testId: string;
    channel: string;
    loadTime: number;
    loadTimestamp: string;
    analyticsPagename: string;
    sectionName: string;
    lastTrackedPage: string;
    cmsName: string;
    noTracking: string;
    eventName: string;
  };
  education: {
    courseId: string;
    courseName: string;
    isbn: string;
    assignmentId: string;
    assignmentName: string;
    assignmentType: string;
    moduleId: string;
    moduleName: string;
    moduleType: string;
    studentId: string;
    instructorId: string;
    semester: string;
    beforeAfterDueDate: string; // date compared to assignment due date in the format "due date +||- Int"
    assignmentNumericGrade: string;
    assignmentDuration: string;
    assignmentScore: string;
  };
}

export enum AdobeAnalyticsAction {
  ASSIGNMENT_CREATED = 'assignmentCreated',
  ASSIGNMENT_STARTED = 'assignmentStarted',
  ASSESSMENT_STARTED = 'assessmentStarted',
  ASSIGNMENT_COMPLETED = 'assignmentCompleted',
  ASSESSMENT_COMPLETED = 'assessmentCompleted',
}

export type AdobeAnalyticsActionProps = {
  action: AdobeAnalyticsAction.ASSIGNMENT_CREATED
    | AdobeAnalyticsAction.ASSIGNMENT_STARTED
    | AdobeAnalyticsAction.ASSIGNMENT_COMPLETED;
  pageData: {
    education: {
      assignmentId: string;
      assignmentName: string;
      assignmentType: string;
      beforeAfterDueDate: string;
    };
  };
} | {
  action: AdobeAnalyticsAction.ASSESSMENT_STARTED
    | AdobeAnalyticsAction.ASSESSMENT_COMPLETED;
  pageData: {
    education: {
      assignmentType: string;
    };
  };
}
